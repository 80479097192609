<template>
  <div class="the-aside-left">
    <nav class="the-aside-left__nav">
      <div
        v-for="(value, name) in visibleMenuItems"
        :key="name"
        :class="[
          'the-aside-left__nav-menu',
          { 'the-aside-left__nav-menu--bottom': isSettingsMenu(name) }
        ]"
      >
        <h2
          v-if="!isSettingsMenu(name)"
          class="the-aside-left__nav-menu-title"
        >
          {{ name }}
        </h2>
        <template v-for="(library, index) in value">
          <router-link
            :key="`${name}-library-${index}`"
            :to="{ path: library.link }"
            :class="[
              'the-aside-left__nav-menu-item',
              {
                'the-aside-left__nav-menu-item--settings': isSettingsMenu(name)
              }
            ]"
          >
            <i
              class="the-aside-left__nav-menu-item-icon"
              :style="{
                webkitMask: `url('${library.icon}') no-repeat center`,
                mask: `url('${library.icon}') no-repeat center`,
                maskSize: 'contain',
                webkitMaskSize: 'contain'
              }"
            />
            {{ library.title }}
            <i
              class="el-icon-arrow-right the-aside-left__nav-menu-item-arrow-icon"
            />
          </router-link>
        </template>
      </div>
    </nav>
    <div class="the-aside-left-version">
      Build ID: {{ version }}
    </div>
  </div>
</template>

<script>
// canUserAccessStitchPlugin
import FeatureFlags from '@/services/featureFlags'
import { canPermitAction } from '@/services/permissions'
import { ROLE_FLAG } from '@/constants/roleFlag'

export default {
  name: 'TheAsideLeft',

  data () {
    return {
      version: process.env.SENTRY_RELEASE
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    menuItems () {
      return [
        {
          name: 'Libraries',
          show: () => canPermitAction(ROLE_FLAG.VISIBLE_LIBRARY),
          items: [
            {
              title: 'Blocks',
              link: '/library/blocks',
              icon: require('@/assets/icons/block.svg')
            },
            {
              title: 'Trims',
              link: '/library/trims',
              icon: require('@/assets/icons/trim.svg')
            },
            {
              title: 'Fabrics',
              link: '/library/fabrics',
              icon: require('@/assets/icons/fabric.svg')
            },
            {
              title: 'Stitches',
              link: '/library/stitches',
              icon: require('@/assets/icons/stitch.svg')
            }
          ]
        },
        {
          name: 'Workspaces',
          items: [
            {
              title: 'My Renders',
              link: '/jobs',
              icon: require('@/assets/icons/render.svg'),
              show: () => canPermitAction(ROLE_FLAG.VISIBLE_RENDERS)
            },
            {
              title: 'Styles',
              link: '/styles',
              icon: require('@/assets/icons/style.svg'),
              show: () => canPermitAction(ROLE_FLAG.VISIBLE_STYLE)
            }
          ]
        },
        {
          name: 'Settings',
          items: [
            {
              title: 'Stitch Plugin',
              link: '/plugin',
              icon: require('@/assets/icons/plugin.svg'),
              show: FeatureFlags.canUserAccessStitchPlugin
            }
          ]
        }
      ]
    },

    /**
     * @returns {Array}
     */
    visibleMenuItems () {
      const menuGroups = this.menuItems.filter(
        group => group.show === undefined || group.show()
      )

      return menuGroups.reduce(
        (accumulator, value) => ({
          ...accumulator,
          [value.name]: value.items.filter(
            item => item.show === undefined || item.show()
          )
        }),
        {}
      )
    }
  },

  methods: {
    /**
     * @param   {string}  menuName
     *
     * @returns {boolean}
     */
    isSettingsMenu (menuName) {
      return menuName.toLowerCase() === 'settings'
    }
  }
}
</script>

<style lang="scss" scoped>
$menu-icon-size: spacing(4);

.the-aside-left {
  width: $aside-width;
  padding: spacing(2) spacing(4) spacing(4) spacing(2);
  font-family: $primary-font;
}

.the-aside-left__nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: spacing(2);
  border: $border-divider;
  border-radius: spacing(1/2);
  box-shadow: $shadow-default;
}

.the-aside-left__nav-menu-item-icon {
  width: $menu-icon-size;
  height: $menu-icon-size;
  margin-right: spacing(1/2);
  background-color: $font-color-default;
  transition: background-color $transition;
}

.the-aside-left__nav-menu-item-arrow-icon {
  position: absolute;
  right: 0;
}

.the-aside-left__nav-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: spacing(1/2) 0;
  color: $grey-dark;
  border-top: $border-divider;
  transition: color $transition;

  &:hover {
    color: $font-color-active;
  }

  &:last-of-type {
    margin-bottom: spacing(2);
    border-bottom: $border-divider;
  }
}

.the-aside-left .the-aside-left__nav-menu-item--settings {
  border-top: 0;
  border-bottom: 0;
}

.the-aside-left__nav-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(2);
}

.the-aside-left__nav-menu--bottom {
  margin-top: auto;
  margin-bottom: 0;

  .the-aside-left__nav-menu-item {
    margin-bottom: 0;
  }

  .the-aside-left__nav-menu-item-arrow-icon {
    display: none;
  }
}

.the-aside-left__nav-menu-title {
  margin-bottom: spacing(1);
  text-transform: capitalize;
}

.router-link-active {
  color: $font-color-active;

  .the-aside-left__nav-menu-item-icon {
    background-color: $font-color-active;
  }
}

.the-aside-left-version {
  @include text-debug;

  position: absolute;
  bottom: 0;
  opacity: 0.5;
}
</style>
